@font-face {
  font-family: 'DIN Engschrift Std';
  src: url('/fonts/DINEngschriftStd.woff2') format('woff2'),
      url('/fonts/DINEngschriftStd.woff') format('woff'),
      url('/fonts/DINEngschriftStd.svg#DINEngschriftStd') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Condensed Demi';
  src: url('/fonts/DINCondensed-DemiBold.woff2') format('woff2'),
      url('/fonts/DINCondensed-DemiBold.woff') format('woff'),
      url('/fonts/DINCondensed-DemiBold.svg#DINCondensed-DemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
